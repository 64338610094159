import "./src/styles/css/bootstrap.css"
import "./src/styles/css/style.css"
import "./src/styles/css/easy-sidebar.css"
import $ from "jquery"
if (typeof window !== "undefined") {
  window.jQuery = window.$ = $
}
export const onRouteUpdate = ({ location, prevLocation }) => {
  window.$("body").removeClass("toggled")
  window.$("navbar.easy-sidebar").removeClass("toggled")
}
